import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import HighlightLink from '~components/HighlightLink'
import Section from '~components/Section'
import Image from '~components/Image'

const Highlights = ({ className, title, content }) => {

	const [integer, setInteger] = useState(0)
	return (
		<Wrap className={className}>
			{content?.map((c, i) => (
				<BackgroundImage 
					key={c.backgroundImage?.asset?._id}
					visible={i === integer} 
					image={c.backgroundImage} 
					cover
				/>
			))}
			<Title>
				{title}
			</Title>
			{content?.map((s, i) => (
				<HighlightLink 
					key={s?.document?.title} 
					content={s.document} 
					subtitle={s.subtitle}
					onActive={() => setInteger(i)} 
					removeActive={() => setInteger(0)}
					active={integer === i} 
					i={i}
				/>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: 230px;
	padding-bottom: 120px;
	min-height: 100vh;
	box-sizing: border-box;
`
const Title = styled.h1`
	grid-column: span 12;
	margin-bottom: 22px;
`
const BackgroundImage = styled(Image)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	opacity: ${ props => props.visible ? '0.4' : '0'};
	transition: opacity 0.4s;
	${mobile}{
		display: none;
	}
`

Highlights.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.array,
}

export default Highlights