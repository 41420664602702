import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'
import SanityLink from '~components/SanityLink'
import RichText from '~components/RichText'

const ImageHighlights = ({ className, title, content }) => {
	
	const [integer, setInteger] = useState(0)

	return (
		<Wrap className={className}>
			{content?.map((c, i) => (
				<BackgroundImage 
					key={c.image?.asset?._id}
					visible={i === integer} 
					image={c.image} 
					cover
				/>
			))}
			<Title>{title}</Title>
			<List>
				{content?.map((c, i) => (
					<Hover 
						key={c.image?.asset?._id}
						active={i === integer} 
						cover
						onMouseEnter={() => setInteger(i)}
						onMouseLeave={() => setInteger(0)}
					>
						<HoverTitle className='h1' active={i === integer} color={c.button?.link?.document?.keyColour}>
							<SanityLink link={c.button?.link}>
								{c.button?.text}
							</SanityLink>
						</HoverTitle>
						<Text>
							<TextInner active={i === integer} >
								<RichText content={c.button?.link?.document?.shortDescription}/>
							</TextInner>
						</Text>
					</Hover>
				))}
			</List>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	position: relative;
	min-height: 100vh;
	padding-top: 120px;
`
const Title = styled.h1`
	margin-bottom: 22px;
`
const BackgroundImage = styled(Image)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	opacity: ${ props => props.visible ? '0.4' : '0'};
	transition: opacity 0.4s;
	${mobile}{
		display: none;
	}
`
const Hover = styled.div`
	opacity: ${ props => props.active ? '1' : '0.5'};
	display: flex;
	padding-bottom: 60px;
	${tablet}{
		padding-bottom: 30px;
	}
`
const List = styled.div`
	grid-column: span 12;
`
const HoverTitle = styled.div`
	width: 50%;
	color: ${ props => props.active ? props.color : 'inherit'};
	transition: color 0.25s;
	padding-right: 20px;
`
const Text = styled.div`
	width: 50%;
	position: relative;
`
const TextInner = styled.div`
	position: absolute;
	top: 0;
	opacity: ${ props => props.active ? '1' : '0'};
	pointer-events: ${ props => props.active ? 'all' : 'none'};
`

ImageHighlights.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.object,
}

export default ImageHighlights