import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const HighlightLink = ({ className, content, onActive, i, active, removeActive, subtitle }) => {	
	return (
		<Wrap>
			<Hover 
				className={className + ' h1'} 
				onMouseEnter={() => onActive(i)}
				onMouseLeave={() => removeActive()}
				active={active}
			>
				<Link to={resolveLink(content)}>
					<Title active={active} color={content?.course[0]?.keyColour}>{content?.title} </Title>
					<Desc active={active}>{subtitle ? subtitle : content?.description ? content?.description : content?.subtitle}</Desc>
				</Link>
			</Hover>
		</Wrap>
	)
}


const Wrap = styled.div`
	grid-column: span 12;
`
const Hover = styled.div`
	display: inline-block;
	opacity: ${ props => (props.hover || props.active) ? '1' : '0.5'};
	transition: opacity 0.25s;
`
const Title = styled.span`
	color: ${ props => props.active ? props.color : 'inherit'};
	transition: color 0.25s;
`
const Desc = styled.span`
	opacity: ${ props => (props.hover || props.active) ? '1' : '0'};
	transition: opacity 0.25s;
`

HighlightLink.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	onActive: PropTypes.func,
	i: PropTypes.func,
	active: PropTypes.func,
	removeActive: PropTypes.func,
	subtitle: PropTypes.string,
}

export default HighlightLink