import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'

const ImageHighlightsMobile = ({ className, title, content }) => {
	return (
		<Wrap className={className}>
			<Section><CourseTitle>{title}</CourseTitle></Section>
			{content.map((c, i) => (	
				<React.Fragment key={c.image?.asset?._id}>
					<SanityLink link={c.button?.link}>
						<Course>
							<BgImage 
								image={c.image} 
							// aspectRatio={0.916}
							/>
							<Content>
								<Title 
									color={c.button?.link?.document?.keyColour}
									className='h1'
								>
									{c.button?.text}
								</Title>
								<Desc>
									<RichText content={c.button?.link?.document?.shortDescription}/>
								</Desc>
							</Content>
						</Course>
					</SanityLink>
				</React.Fragment>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
`
const LinkOverlay = styled(SanityLink)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
`
const Course = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
`
const CourseTitle = styled.h1`
	grid-column: span 12;
	margin-bottom: 20px;
`
const BgImage = styled(Image)`
	grid-column: 1/2;
	grid-row: 1;
	z-index: -1;
	opacity: 0.4;
`
const Content = styled(Section)`
	grid-column: 1/2;
	grid-row: 1;
	padding-top: 146px;
`
const Title = styled.div`
	grid-column: span 12;
	color: ${ props => props.color };
	margin-bottom: 12px;
`
const Desc = styled.div`
	grid-column: span 12;
	padding-bottom: 50px;
`

ImageHighlightsMobile.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.array,
}

export default ImageHighlightsMobile