import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import { Collapse } from 'react-collapse'
import Image from '~components/Image'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'


const HighlightsMobile = ({ className, title, content }) => {
	
	const [open, setOpen] = useState(0)

	return (
		<Wrap className={className}>
			<Title>
				{title}
			</Title>
			{content?.map((c, i) => (
				<HighlightDropdown className='h1' key={c?.title}>
					<Trigger onClick={() => setOpen(i)}>
						<Red active={open === i}>{c?.document?.title}</Red>
					</Trigger>
					<Collapse isOpened={open === i}>
						<Link to={resolveLink(c.document)}>
							<Desc>{c?.subtitle ? c?.subtitle : c?.document?.description}</Desc>
							<FullImage aspectRatio={1.5} image={c.document?.work ? c?.document?.work[0] : c?.backgroundImage}/>
						</Link>
					</Collapse>
				</HighlightDropdown>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: 50px;
	margin-bottom: 120px;
	> div{
		padding: 0;
	}
`
const Title = styled.h1`
	grid-column: span 12;
	padding: 0 20px;
`
const HighlightDropdown = styled.div`
	grid-column: span 12;
`
const Trigger = styled.button`
	text-align: left;
	padding: 0 20px;
`
const Red = styled.div`
	color: ${ props => props.active ? 'var(--red)' : 'var(--white)'};
	transition: color 0.3s;
`
const Desc = styled.div`
	padding: 0 20px 12px;
`
const FullImage = styled(Image)`
	padding-bottom: 24px;
`

HighlightsMobile.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.object,
}

export default HighlightsMobile